/* eslint-disable no-unused-vars */
import { Dispatch } from 'react';
import { User } from '../__generated__/graphql';

export type AppState = {
  currentUser: User;
  authenticated: boolean;
  authToken: string | null;
  currentRole?: string;
  refreshToken?: string | null;
  userEmail: string;
  workspace: string | null;
};

export enum AppActionType {
  setCurrentUser = 'SET_CURRENT_USER',
  setAuthenticated = 'SET_AUTHENTICATED',
  setAuthToken = 'SET_TOKEN',
  setCurrentRole = 'SET_CURRENT_ROLE',
  setRefreshToken = 'SET_REFRESH_TOKEN',
  logout = 'LOGOUT',
  setEmail = 'SET_EMAIL',
  setWorkspace = 'SET_WORKSPACE',
}

export type AppAction =
  | { type: AppActionType.setCurrentUser; data: User }
  | { type: AppActionType.setAuthenticated; data: boolean }
  | { type: AppActionType.setAuthToken; data: string | null }
  | { type: AppActionType.setRefreshToken; data: string | null }
  | { type: AppActionType.setCurrentRole; data: string }
  | { type: AppActionType.logout }
  | { type: AppActionType.setEmail; data: string }
  | { type: AppActionType.setWorkspace; data: string | null };

export type AppContextType = {
  state: AppState;
  dispatch: Dispatch<AppAction>;
  getToken: () => string | null;
  isAuthenticated: () => boolean;
  getRefreshToken: () => string | null;
  initializeAuth: (
    authToken: string | null,
    userData: User | null,
    refreshToken?: string | null,
  ) => void;
  getCurrentUser: () => void;
};
