import dayjs from 'dayjs';
import isoWeekday from 'dayjs/plugin/isoWeek';
import { useMemo } from 'react';
import { LeftArrow, RightArrow } from '../../../assets/svg';
import {
  CALL_TYPE,
  HOUR_TIME_FORMAT,
  MONTHS,
  MONTH_DATE_FORMAT,
  WEEKDAYS,
} from '../../../common/constants';
import LoaderComponent from '../../../components/LoaderComponent';
import { BookingBodyPropsType } from '../booking.type';

dayjs.extend(isoWeekday);

const BookingBody = ({
  type,
  setCurrentMonth,
  currentMonth,
  setCurrentYear,
  currentYear,
  setCurrentSlot,
  currentSlot,
  currentDate,
  handleDateSelect,
  hourlySlots,
  data,
  unavailableData,
  loading,
  upcomingSessionsData,
}: BookingBodyPropsType) => {
  // Function to handle incrementing the month
  const incrementMonth = () => {
    setCurrentMonth((prevMonth) => (prevMonth + 1) % 12);
    // If incrementing past December, also update the year
    if (currentMonth === 11) {
      setCurrentYear((prevYear) => prevYear + 1);
    }
  };

  // Function to handle decrementing the month
  const decrementMonth = () => {
    setCurrentMonth((prevMonth) => (prevMonth - 1 + 12) % 12);
    // If decrementing past January, also update the year
    if (currentMonth === 0) {
      setCurrentYear((prevYear) => prevYear - 1);
    }
  };

  // this weeks upcoming sessions
  const thisWeekSessions = useMemo(() => {
    const shiftedArray = upcomingSessionsData
      ? upcomingSessionsData?.slice(1)
      : [];
    return shiftedArray;
  }, [upcomingSessionsData]);

  const isBeforeMonth = useMemo(() => {
    return dayjs().isBefore(
      dayjs().year(currentYear).month(currentMonth).startOf('month'),
      'month',
    );
  }, [currentMonth, currentYear]);

  const monthlyData = useMemo(() => {
    const fullDayUnavailable = unavailableData?.filter(
      (item) => item?.availabilityDetails?.[0]?.timeSlots?.length === 0,
    );

    const filterData = data?.monthlyAvailability?.data?.filter((item) => {
      const currentDate = dayjs()
        .date(item?.date ?? 1)
        .month(currentMonth)
        .year(currentYear);

      const isCurrentDateUnavailable = fullDayUnavailable?.some((item) => {
        return currentDate.isBetween(
          dayjs(item?.startDate),
          dayjs(item?.endDate).endOf('day'),
        );
      });
      return !currentDate.isBefore(dayjs()) && !isCurrentDateUnavailable;
    });
    return filterData;
  }, [data?.monthlyAvailability?.data, unavailableData]);

  return (
    <>
      <div className="booking-days-wrapper">
        <div className="booking-header mb-16">
          <h3 className="booking-heading">
            {type === CALL_TYPE.WEBINAR ? 'Upcoming session' : 'Pick a date'}
          </h3>
          {type !== CALL_TYPE.WEBINAR && (
            <div className="booking-header-month">
              <span
                className={`navigation-arrow ${!isBeforeMonth ? 'disabled' : ''}`}
                onClick={() => {
                  if (isBeforeMonth) {
                    decrementMonth();
                  }
                }}
              >
                <LeftArrow />
              </span>
              <p>
                {MONTHS[currentMonth]} {currentYear}
              </p>
              <span className="navigation-arrow" onClick={incrementMonth}>
                <RightArrow />
              </span>
            </div>
          )}
        </div>
        <div
          className={`booking-days-block ${type === CALL_TYPE.WEBINAR ? 'webinar-layout' : ''}`}
        >
          {loading && (
            <div className="full-width">
              <LoaderComponent setHeight={10} size="default" />
            </div>
          )}
          {!loading && type === CALL_TYPE.WEBINAR ? (
            upcomingSessionsData?.length > 0 ? (
              <div
                className={`booking-day webinar ${upcomingSessionsData?.[0] === currentSlot ? 'active' : ''}`}
                onClick={() => {
                  setCurrentSlot(upcomingSessionsData?.[0]);
                }}
              >
                <p className="booking-date-text">
                  {dayjs(upcomingSessionsData?.[0]).format(MONTH_DATE_FORMAT)}
                </p>
                <p className="booking-day-text">
                  {`${dayjs(upcomingSessionsData?.[0]).format(
                    HOUR_TIME_FORMAT,
                  )} ${data?.monthlyAvailability?.eventType?.duration}m`}
                </p>
              </div>
            ) : (
              <div className="no-slots-block">
                <h4>No Upcoming Sessions available</h4>
              </div>
            )
          ) : monthlyData && monthlyData?.length > 0 ? (
            monthlyData?.map((day, i) => (
              <div
                key={`day${i}`}
                className={`booking-day ${day?.date === currentDate ? 'active' : ''}`}
                onClick={() =>
                  handleDateSelect({
                    date: day?.date ?? 1,
                    slot: day?.timeSlots,
                    duration:
                      data?.monthlyAvailability?.eventType?.duration ?? 0,
                    buffer: data?.monthlyAvailability?.eventType?.buffer ?? 0,
                  })
                }
              >
                <p className="booking-day-text">
                  {day?.date
                    ? WEEKDAYS[
                        dayjs()
                          .date(day?.date)
                          .month(currentMonth)
                          .year(currentYear)
                          .isoWeekday()
                      ]
                    : '-'}
                </p>
                <p className="booking-date-text">{`${day?.date} ${MONTHS[currentMonth]?.slice(0, 3)}`}</p>
              </div>
            ))
          ) : (
            <div className="no-slots-block">
              <h4>No date available</h4>
            </div>
          )}
        </div>
      </div>
      <div className="booking-slots-wrapper">
        <p className="booking-heading mb-16">
          {type === CALL_TYPE.WEBINAR
            ? upcomingSessionsData?.length > 0
              ? `${thisWeekSessions?.length > 0 ? thisWeekSessions?.length : 'No'} more session this week`
              : ''
            : 'Pick a time'}
        </p>
        <div
          className={`booking-slots-block ${type === CALL_TYPE.WEBINAR ? 'webinar-layout' : ''}`}
        >
          {type === CALL_TYPE.WEBINAR ? (
            thisWeekSessions?.length > 0 &&
            thisWeekSessions?.map((item, i) => (
              <div key={i} className="booking-day webinar disabled">
                <p className="booking-date-text">
                  {dayjs(item).format(MONTH_DATE_FORMAT)}
                </p>
                <p className="booking-day-text">
                  {`${dayjs(item).format(
                    HOUR_TIME_FORMAT,
                  )} ${data?.monthlyAvailability?.eventType?.duration}m`}
                </p>
              </div>
            ))
          ) : hourlySlots?.length > 0 ? (
            hourlySlots?.map((slot, i) => (
              <div
                key={`slot${i}`}
                className={`booking-slot ${currentSlot === slot.startTime ? 'active' : ''} ${slot?.isBooked === true ? 'slot-booked' : ''}`}
                onClick={() => setCurrentSlot(slot.startTime)}
              >
                <span>
                  {dayjs(slot?.startTime).format(HOUR_TIME_FORMAT) ?? ''}
                </span>
              </div>
            ))
          ) : (
            <div className="no-slots-block">
              <h4>No slots available</h4>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default BookingBody;
