import { RcFile } from 'antd/es/upload';
import { NavigateFunction } from 'react-router-dom';
import { messageContext } from '../components/AppContextHolder';
import { REGEX } from './constants';

export const formValidatorRules = {
  required: {
    required: true,
    message: 'Required',
  },
  email: () => ({
    validator(_rule: unknown, value?: string) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!REGEX.EMAIL?.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('The input is not valid E-mail!');
      }
      return Promise?.resolve();
    },
  }),
  name: () => ({
    validator(_rule: unknown, value?: string) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!REGEX.NAME?.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Please enter valid name');
      }
      return Promise?.resolve();
    },
  }),
  number: () => ({
    validator(_rule: unknown, value: string) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!Number(value) || !REGEX.NUMBER?.test(Number(value).toString())) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Should be a valid Number');
      }
      return Promise?.resolve();
    },
  }),
};

export const getBase64 = (file: RcFile) =>
  new Promise((resolve, reject) => {
    // eslint-disable-next-line no-undef
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader?.result);
    reader.onerror = (error) => reject(error);
  });

export const getTimeFromMins = (mins: number) => {
  const hours = Math.floor(mins / 60);
  const minutes = mins % 60;
  return `${hours}h ${minutes}m`;
};

export const getBase64File = (
  img: Blob,
  // eslint-disable-next-line no-unused-vars
  callback: (result: string | ArrayBuffer | null) => void,
) => {
  // eslint-disable-next-line no-undef
  const reader = new FileReader();
  reader?.addEventListener('load', () => callback(reader?.result));
  reader?.readAsDataURL(img);
};

export const beforeUpload = (file: RcFile) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    messageContext.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file?.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    messageContext.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
};

export const handleProtectedNavigation = (
  allow: boolean,
  callback: NavigateFunction,
  path: number,
) => (allow ? callback(path) : false);

// Portal related methods
export const injectUsingPortal = (portalId: string) =>
  // eslint-disable-next-line no-undef
  document.getElementById(portalId);

export const isPortalIdExists = (portalId: string) =>
  !!injectUsingPortal(portalId);

export const setItem = (name: string, value: string) => {
  // eslint-disable-next-line no-undef
  localStorage?.setItem(name, value);
};

export const getItem = (name: string) => {
  // eslint-disable-next-line no-undef
  const value = localStorage?.getItem(name);
  return value;
};

export const removeItem = (name: string) => {
  // eslint-disable-next-line no-undef
  localStorage.removeItem(name);
};

const isImageFileType = (type: string) => type?.indexOf('image/') === 0;

export const previewImage: (
  // eslint-disable-next-line no-unused-vars
  file: File | Blob,
  // eslint-disable-next-line no-unused-vars
  size?: number,
) => Promise<string> = (file, size = 64) =>
  new Promise((resolve) => {
    if (!file?.type || !isImageFileType(file?.type)) {
      resolve('');
      return;
    }

    // eslint-disable-next-line no-undef
    const canvas = document?.createElement('canvas');
    canvas.width = size;
    canvas.height = size;
    canvas.style.cssText = `position: fixed; left: 0; top: 0; width: ${size}px; height: ${size}px; z-index: 9999; display: none;`;
    // eslint-disable-next-line no-undef
    document?.body?.appendChild(canvas);
    const ctx = canvas?.getContext('2d');
    // eslint-disable-next-line no-undef
    const img = new Image();
    img.onload = () => {
      const { width, height } = img;

      let drawWidth = size;
      let drawHeight = size;
      let offsetX = 0;
      let offsetY = 0;

      if (width < height) {
        drawHeight = height * (size / width);
        offsetY = -(drawHeight - drawWidth) / 2;
      } else {
        drawWidth = width * (size / height);
        offsetX = -(drawWidth - drawHeight) / 2;
      }

      ctx?.drawImage(img, offsetX, offsetY, drawWidth, drawHeight);
      const dataURL = canvas?.toDataURL();
      // eslint-disable-next-line no-undef
      document?.body?.removeChild(canvas);

      resolve(dataURL);
    };
    // eslint-disable-next-line no-undef
    img.src = window?.URL?.createObjectURL(file);
  });

// create cookie
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setCookie = (
  name: string,
  value: string | number | boolean,
  minutes: number,
) => {
  let expires = '';
  if (minutes) {
    const date = new Date();
    date?.setTime(date?.getTime() + minutes * 60 * 1000);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    expires = `; expires=${(date as any)?.toGMTString()}`; //as using toGMTString
  }
  // eslint-disable-next-line no-undef
  document.cookie = `${name}=${value}${expires}; path=/`;
};

// retrieve cookie
export const getCookie = (cookieName: string) => {
  const name = `${cookieName}=`;
  let returnCookie = '';
  // eslint-disable-next-line no-undef
  const decodedCookie = decodeURIComponent(document?.cookie);
  const cookieArray = decodedCookie?.split(';');
  if (cookieArray?.length > 0) {
    // eslint-disable-next-line array-callback-return
    cookieArray?.map((item) => {
      let cookie = item;
      while (cookie?.charAt(0) === ' ') {
        cookie = cookie?.substring(1);
      }
      if (cookie?.indexOf(name) === 0) {
        returnCookie = cookie?.substring(name?.length, cookie?.length);
      }
    });
    return returnCookie;
  }
};

// force expired cookie or delete
export const deleteCookie = (name: string) => {
  // eslint-disable-next-line no-undef
  document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};

export const formItemProps = { normalize: (value: string) => value?.trim() };
