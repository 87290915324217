/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  mutation Login($data: LoginInput!) {\n    login(data: $data) {\n      data {\n        email\n        emailVerifiedAt\n      }\n      message\n    }\n  }\n": types.LoginDocument,
    "\n  mutation VerifyOtp($data: VerifyOtpInput!) {\n    verifyOtp(data: $data) {\n      accessToken\n      message\n      data {\n        email\n        firstName\n        lastName\n        id\n        profileImage\n        roles\n      }\n    }\n  }\n": types.VerifyOtpDocument,
    "\nmutation UpdateUser($data: UpdateUserInput!) {\n  updateUser(data: $data) {\n    message\n    data {\n      lastName\n      firstName\n      id\n      email\n      profileImage\n    }\n  }\n}\n": types.UpdateUserDocument,
    "query CurrentUser {\n  currentUser {\n    email\n    id\n    firstName\n    lastName\n    profileImage\n    roles\n  }\n}": types.CurrentUserDocument,
    "mutation Logout {\n  logout {\n    message\n  }\n}": types.LogoutDocument,
    "\n  query Workspaces($filter: ListWorkspaceInput!) {\n    workspaces(filter: $filter) {\n      data {\n        id\n        image\n        name\n        uuid\n      }\n      count\n    }\n  }\n": types.WorkspacesDocument,
    "query GetProfileImageUploadSignedUrl($data: GetProfileImageUploadSignedUrlInput!) {\n  getProfileImageUploadSignedUrl(data: $data) {\n    key\n    signedUrl\n  }\n}": types.GetProfileImageUploadSignedUrlDocument,
    "mutation CreateBooking($data: CreateBookingInput!) {\n  createBooking(data: $data) {\n    message\n    data {\n      id\n      endTime\n      startTime\n      status\n    }\n  }\n}": types.CreateBookingDocument,
    "query MonthlyAvailability($filter: GetMonthlyAvailabilityFilter!) {\n  monthlyAvailability(filter: $filter) {\n    data {\n      date\n      day\n      timeSlots\n    }\n    count\n    eventType {\n      id\n      duration\n      buffer\n      type\n      upcomingSessionDates\n      sessionStartDate\n      dateRangeType\n    }\n  }\n}": types.MonthlyAvailabilityDocument,
    "query Bookings($filter: BookingsFilter!) {\n  bookings(filter: $filter) {\n    data {\n      startTime\n      endTime\n      status\n    }\n  }\n}": types.BookingsDocument,
    "query MonthlyBookings($filter: MonthlyBookingsFiler!) {\n  monthlyBookings(filter: $filter) {\n    count\n    data {\n     startTime\n      endTime\n      status\n    }\n  }\n}": types.MonthlyBookingsDocument,
    "query Availability($filter: GetAvailabilityFilter!) {\n  availability(filter: $filter) {\n    data {\n      startDate\n      endDate\n      isUnavailability\n      availabilityDetails\n    }\n  }\n}": types.AvailabilityDocument,
    "mutation CreateWebhook($data: CreateWebhookInput!) {\n  createWebhook(data: $data) {\n    message\n  }\n}": types.CreateWebhookDocument,
    "mutation DeleteWebhook($where: DeleteWebhookWhereUnique!) {\n  deleteWebhook(where: $where) {\n    message\n  }\n}\n": types.DeleteWebhookDocument,
    "query WorkspaceCredentials($filter: ListWorkspaceCredentialsInput!) {\n  workspaceCredentials(filter: $filter) {\n    count\n    data {\n      accessId\n      id\n      secretKey\n    }\n  }\n}": types.WorkspaceCredentialsDocument,
    "query WorkspaceWebhooks($filter: ListWorkspaceWebhooksInput!, $sort: [ListWorkspaceWebhooksSort!]) {\n    workspaceWebhooks(filter: $filter, sort: $sort) {\n      data {\n        url\n        events\n        description\n        id\n      }\n    }\n  }": types.WorkspaceWebhooksDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation Login($data: LoginInput!) {\n    login(data: $data) {\n      data {\n        email\n        emailVerifiedAt\n      }\n      message\n    }\n  }\n"): (typeof documents)["\n  mutation Login($data: LoginInput!) {\n    login(data: $data) {\n      data {\n        email\n        emailVerifiedAt\n      }\n      message\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation VerifyOtp($data: VerifyOtpInput!) {\n    verifyOtp(data: $data) {\n      accessToken\n      message\n      data {\n        email\n        firstName\n        lastName\n        id\n        profileImage\n        roles\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation VerifyOtp($data: VerifyOtpInput!) {\n    verifyOtp(data: $data) {\n      accessToken\n      message\n      data {\n        email\n        firstName\n        lastName\n        id\n        profileImage\n        roles\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation UpdateUser($data: UpdateUserInput!) {\n  updateUser(data: $data) {\n    message\n    data {\n      lastName\n      firstName\n      id\n      email\n      profileImage\n    }\n  }\n}\n"): (typeof documents)["\nmutation UpdateUser($data: UpdateUserInput!) {\n  updateUser(data: $data) {\n    message\n    data {\n      lastName\n      firstName\n      id\n      email\n      profileImage\n    }\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "query CurrentUser {\n  currentUser {\n    email\n    id\n    firstName\n    lastName\n    profileImage\n    roles\n  }\n}"): (typeof documents)["query CurrentUser {\n  currentUser {\n    email\n    id\n    firstName\n    lastName\n    profileImage\n    roles\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "mutation Logout {\n  logout {\n    message\n  }\n}"): (typeof documents)["mutation Logout {\n  logout {\n    message\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Workspaces($filter: ListWorkspaceInput!) {\n    workspaces(filter: $filter) {\n      data {\n        id\n        image\n        name\n        uuid\n      }\n      count\n    }\n  }\n"): (typeof documents)["\n  query Workspaces($filter: ListWorkspaceInput!) {\n    workspaces(filter: $filter) {\n      data {\n        id\n        image\n        name\n        uuid\n      }\n      count\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "query GetProfileImageUploadSignedUrl($data: GetProfileImageUploadSignedUrlInput!) {\n  getProfileImageUploadSignedUrl(data: $data) {\n    key\n    signedUrl\n  }\n}"): (typeof documents)["query GetProfileImageUploadSignedUrl($data: GetProfileImageUploadSignedUrlInput!) {\n  getProfileImageUploadSignedUrl(data: $data) {\n    key\n    signedUrl\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "mutation CreateBooking($data: CreateBookingInput!) {\n  createBooking(data: $data) {\n    message\n    data {\n      id\n      endTime\n      startTime\n      status\n    }\n  }\n}"): (typeof documents)["mutation CreateBooking($data: CreateBookingInput!) {\n  createBooking(data: $data) {\n    message\n    data {\n      id\n      endTime\n      startTime\n      status\n    }\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "query MonthlyAvailability($filter: GetMonthlyAvailabilityFilter!) {\n  monthlyAvailability(filter: $filter) {\n    data {\n      date\n      day\n      timeSlots\n    }\n    count\n    eventType {\n      id\n      duration\n      buffer\n      type\n      upcomingSessionDates\n      sessionStartDate\n      dateRangeType\n    }\n  }\n}"): (typeof documents)["query MonthlyAvailability($filter: GetMonthlyAvailabilityFilter!) {\n  monthlyAvailability(filter: $filter) {\n    data {\n      date\n      day\n      timeSlots\n    }\n    count\n    eventType {\n      id\n      duration\n      buffer\n      type\n      upcomingSessionDates\n      sessionStartDate\n      dateRangeType\n    }\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "query Bookings($filter: BookingsFilter!) {\n  bookings(filter: $filter) {\n    data {\n      startTime\n      endTime\n      status\n    }\n  }\n}"): (typeof documents)["query Bookings($filter: BookingsFilter!) {\n  bookings(filter: $filter) {\n    data {\n      startTime\n      endTime\n      status\n    }\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "query MonthlyBookings($filter: MonthlyBookingsFiler!) {\n  monthlyBookings(filter: $filter) {\n    count\n    data {\n     startTime\n      endTime\n      status\n    }\n  }\n}"): (typeof documents)["query MonthlyBookings($filter: MonthlyBookingsFiler!) {\n  monthlyBookings(filter: $filter) {\n    count\n    data {\n     startTime\n      endTime\n      status\n    }\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "query Availability($filter: GetAvailabilityFilter!) {\n  availability(filter: $filter) {\n    data {\n      startDate\n      endDate\n      isUnavailability\n      availabilityDetails\n    }\n  }\n}"): (typeof documents)["query Availability($filter: GetAvailabilityFilter!) {\n  availability(filter: $filter) {\n    data {\n      startDate\n      endDate\n      isUnavailability\n      availabilityDetails\n    }\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "mutation CreateWebhook($data: CreateWebhookInput!) {\n  createWebhook(data: $data) {\n    message\n  }\n}"): (typeof documents)["mutation CreateWebhook($data: CreateWebhookInput!) {\n  createWebhook(data: $data) {\n    message\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "mutation DeleteWebhook($where: DeleteWebhookWhereUnique!) {\n  deleteWebhook(where: $where) {\n    message\n  }\n}\n"): (typeof documents)["mutation DeleteWebhook($where: DeleteWebhookWhereUnique!) {\n  deleteWebhook(where: $where) {\n    message\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "query WorkspaceCredentials($filter: ListWorkspaceCredentialsInput!) {\n  workspaceCredentials(filter: $filter) {\n    count\n    data {\n      accessId\n      id\n      secretKey\n    }\n  }\n}"): (typeof documents)["query WorkspaceCredentials($filter: ListWorkspaceCredentialsInput!) {\n  workspaceCredentials(filter: $filter) {\n    count\n    data {\n      accessId\n      id\n      secretKey\n    }\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "query WorkspaceWebhooks($filter: ListWorkspaceWebhooksInput!, $sort: [ListWorkspaceWebhooksSort!]) {\n    workspaceWebhooks(filter: $filter, sort: $sort) {\n      data {\n        url\n        events\n        description\n        id\n      }\n    }\n  }"): (typeof documents)["query WorkspaceWebhooks($filter: ListWorkspaceWebhooksInput!, $sort: [ListWorkspaceWebhooksSort!]) {\n    workspaceWebhooks(filter: $filter, sort: $sort) {\n      data {\n        url\n        events\n        description\n        id\n      }\n    }\n  }"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;