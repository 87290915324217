import { gql } from '../../../__generated__';

export const LOGIN = gql(`
  mutation Login($data: LoginInput!) {
    login(data: $data) {
      data {
        email
        emailVerifiedAt
      }
      message
    }
  }
`);

export const VERIFY_OTP = gql(`
  mutation VerifyOtp($data: VerifyOtpInput!) {
    verifyOtp(data: $data) {
      accessToken
      message
      data {
        email
        firstName
        lastName
        id
        profileImage
        roles
      }
    }
  }
`);

export const UPDATE_CURRENT_USER = gql(`
mutation UpdateUser($data: UpdateUserInput!) {
  updateUser(data: $data) {
    message
    data {
      lastName
      firstName
      id
      email
      profileImage
    }
  }
}
`);
