import { DownOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { Dropdown, Space } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import { ROUTES } from '../../../common/constants';
import { USER_WORKSPACE } from '../../../modules/auth/graphql/queries';
import { AppContextType } from '../../../types/appContext.type';

const WorkspaceList = () => {
  const {
    state: { workspace },
  } = useContext(AppContext) as AppContextType;

  const [workspaces, setWorkspaces] = useState<
    {
      key: string;
      label: JSX.Element;
    }[]
  >();

  const [getWorkspace] = useLazyQuery(USER_WORKSPACE, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const item =
        res.workspaces?.data?.map((ele) => ({
          key: ele?.name as string,
          label: (
            <Link to={`${ROUTES.WORKSPACE}${ROUTES.AUTH_DETAILS}/${ele?.uuid}`}>
              {ele?.name}
            </Link>
          ),
        })) ?? [];
      setWorkspaces(item);
    },
  });

  useEffect(() => {
    getWorkspace({ variables: { filter: { limit: 10, skip: 0 } } });
  }, []);

  return (
    <Dropdown
      menu={{
        items: workspaces,
        selectedKeys: [
          workspaces && workspaces?.length > 0
            ? (workspaces?.[0]?.label as unknown as string)
            : '',
        ],
      }}
      trigger={['click']}
    >
      <Space className="pointer d-flex align-center gap-4" size="small">
        <span className="m-hide">{workspace}</span>
        <DownOutlined />
      </Space>
    </Dropdown>
  );
};

export default WorkspaceList;
