import { Tabs, TabsProps } from 'antd';
import { ReactNode, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ROUTES, WORKSPACE_MODULE } from '../../../common/constants';
import history from '../../../historyData';
import useRouter from '../../../hooks/useRouter';
import AuthDetails from '../pages/AuthDetails';
import Webhook from '../pages/Webhooks';

const WorkspaceTab = ({ children }: { children?: ReactNode }) => {
  const location = useLocation();
  const { params } = useRouter();
  const [activeTabKey, setActiveTabKey] = useState(
    WORKSPACE_MODULE.AUTH_DETAILS.key,
  );
  const tabs: TabsProps['items'] = [
    {
      key: WORKSPACE_MODULE.AUTH_DETAILS.key,
      label: WORKSPACE_MODULE.AUTH_DETAILS.label,
      children: <AuthDetails />,
    },
    {
      key: WORKSPACE_MODULE.WEBHOOKS.key,
      label: WORKSPACE_MODULE.WEBHOOKS.label,
      children: <Webhook />,
    },
  ];

  useEffect(() => {
    if (location?.pathname?.includes(WORKSPACE_MODULE.AUTH_DETAILS.key)) {
      setActiveTabKey(WORKSPACE_MODULE.AUTH_DETAILS.key);
    }
    if (location?.pathname?.includes(WORKSPACE_MODULE.WEBHOOKS.key)) {
      setActiveTabKey(WORKSPACE_MODULE.WEBHOOKS.key);
    }
  }, [location]);

  const onChange = (key: string) => {
    setActiveTabKey(key);
    history.push(`${ROUTES.WORKSPACE}/${key}/${params?.id}`);
  };

  return (
    <>
      <Tabs activeKey={activeTabKey} items={tabs} onChange={onChange}>
        {children}
      </Tabs>
    </>
  );
};
export default WorkspaceTab;
