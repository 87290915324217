/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
  JSON: { input: any; output: any; }
  /** Limit custom scalar type */
  Limit: { input: any; output: any; }
};

export type Availability = {
  __typename?: 'Availability';
  availabilityDetails?: Maybe<Scalars['JSON']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  creator?: Maybe<User>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  eventType?: Maybe<EventType>;
  id?: Maybe<Scalars['ID']['output']>;
  isUnavailability?: Maybe<Scalars['Boolean']['output']>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
  user?: Maybe<User>;
  workspace?: Maybe<Workspace>;
};

export type AvailabilityResponse = {
  __typename?: 'AvailabilityResponse';
  count?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<Array<Maybe<Availability>>>;
};

export type AvailabilityTime = {
  end?: InputMaybe<TimeValues>;
  start?: InputMaybe<TimeValues>;
};

export type Booking = {
  __typename?: 'Booking';
  email?: Maybe<Scalars['String']['output']>;
  endTime?: Maybe<Scalars['DateTime']['output']>;
  eventTypeId?: Maybe<Scalars['ID']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Scalars['String']['output']>;
  startTime?: Maybe<Scalars['DateTime']['output']>;
  status?: Maybe<BookingStatusEnum>;
};

export type BookingSort = {
  sortBy: SortOrder;
  sortOn: ListBookingSortOnField;
};

export enum BookingStatusEnum {
  Active = 'ACTIVE',
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  Fail = 'FAIL',
  Pending = 'PENDING'
}

export type BookingsFilter = {
  endTime?: InputMaybe<Scalars['DateTime']['input']>;
  eventTypeSlug?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  startTime?: InputMaybe<Scalars['DateTime']['input']>;
  statuses?: InputMaybe<Array<InputMaybe<BookingStatusEnum>>>;
  userSlug: Scalars['String']['input'];
};

export type BookingsResponse = {
  __typename?: 'BookingsResponse';
  count?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<Array<Maybe<Booking>>>;
};

export type BulkDeleteAvailabilityWhere = {
  ids: Array<Scalars['ID']['input']>;
};

export type CalendarDetails = {
  day?: InputMaybe<Scalars['Int']['input']>;
  timeSlots: Array<AvailabilityTime>;
};

export type CommonMessageResponse = {
  __typename?: 'CommonMessageResponse';
  message?: Maybe<Scalars['String']['output']>;
};

export type CreateAvailabilityInput = {
  availabilityDetails: Array<CalendarDetails>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  eventTypeId?: InputMaybe<Scalars['ID']['input']>;
  isUnavailability?: InputMaybe<Scalars['Boolean']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CreateAvailabilityResponse = {
  __typename?: 'CreateAvailabilityResponse';
  data?: Maybe<Availability>;
  message?: Maybe<Scalars['String']['output']>;
};

export type CreateBookingInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  endTime: Scalars['DateTime']['input'];
  eventTypeId: Scalars['ID']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  startTime: Scalars['DateTime']['input'];
  status?: InputMaybe<BookingStatusEnum>;
  workspaceId: Scalars['ID']['input'];
};

export type CreateBookingResponse = {
  __typename?: 'CreateBookingResponse';
  data?: Maybe<Booking>;
  message?: Maybe<Scalars['String']['output']>;
};

export type CreateCredentialsInput = {
  expiryDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CreateCredentialsResponse = {
  __typename?: 'CreateCredentialsResponse';
  data?: Maybe<WorkspaceCredentials>;
  message?: Maybe<Scalars['String']['output']>;
};

export type CreateEventTypeInput = {
  audienceSize?: InputMaybe<Scalars['Int']['input']>;
  buffer?: InputMaybe<Scalars['Int']['input']>;
  bufferUnit?: InputMaybe<Scalars['String']['input']>;
  dateCount?: InputMaybe<Scalars['Int']['input']>;
  dateRangeEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  dateRangeStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  dateRangeType?: InputMaybe<DateRangeTypeEnum>;
  description?: InputMaybe<Scalars['String']['input']>;
  duration: Scalars['Int']['input'];
  durationUnit?: InputMaybe<Scalars['String']['input']>;
  link: Scalars['String']['input'];
  recurringSessionDays?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  sessionCount?: InputMaybe<Scalars['Int']['input']>;
  sessionStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  type: EventTypeEnum;
  upcomingSessionDates?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  useDefaultAvailability?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateEventTypeResponse = {
  __typename?: 'CreateEventTypeResponse';
  data?: Maybe<EventType>;
  message?: Maybe<Scalars['String']['output']>;
};

export type CreateWebhookInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  events: Array<WebhookEventsEnum>;
  url: Scalars['String']['input'];
};

export type CreateWebhookResponse = {
  __typename?: 'CreateWebhookResponse';
  data?: Maybe<WorkspaceWebhook>;
  message?: Maybe<Scalars['String']['output']>;
};

export type CreateWorkspaceUserInput = {
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
};

export type CreateWorkspaceUserResponse = {
  __typename?: 'CreateWorkspaceUserResponse';
  data?: Maybe<WorkspaceUser>;
  message?: Maybe<Scalars['String']['output']>;
};

export type Credential = {
  __typename?: 'Credential';
  accessId?: Maybe<Scalars['String']['output']>;
  expiryDate?: Maybe<Scalars['DateTime']['output']>;
  secretKey?: Maybe<Scalars['String']['output']>;
};

export enum DateRangeTypeEnum {
  DaysCalendar = 'DAYS_CALENDAR',
  DaysWeekdays = 'DAYS_WEEKDAYS',
  Range = 'RANGE',
  Years = 'YEARS'
}

export type DeleteAvailabilityWhere = {
  id: Scalars['ID']['input'];
};

export type DeleteWebhookWhereUnique = {
  id: Scalars['ID']['input'];
};

export type EventType = {
  __typename?: 'EventType';
  audienceSize?: Maybe<Scalars['Int']['output']>;
  buffer?: Maybe<Scalars['Int']['output']>;
  bufferUnit?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  creator?: Maybe<User>;
  dateCount?: Maybe<Scalars['Int']['output']>;
  dateRangeEndDate?: Maybe<Scalars['DateTime']['output']>;
  dateRangeStartDate?: Maybe<Scalars['DateTime']['output']>;
  dateRangeType?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['Int']['output']>;
  durationUnit?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isBooked?: Maybe<Scalars['Boolean']['output']>;
  link?: Maybe<Scalars['String']['output']>;
  recurringSessionDays?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  sessionCount?: Maybe<Scalars['Int']['output']>;
  sessionStartDate?: Maybe<Scalars['DateTime']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  upcomingSessionDates?: Maybe<Array<Maybe<Scalars['DateTime']['output']>>>;
  user?: Maybe<User>;
  workspace?: Maybe<Workspace>;
};

export enum EventTypeEnum {
  OneToOne = 'ONE_TO_ONE',
  Webinar = 'WEBINAR'
}

export type EventTypeFilter = {
  createdByAPI?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<EventTypeEnum>;
};

export type EventTypeSlugWhereInput = {
  slug: Scalars['String']['input'];
};

export type EventTypeSort = {
  sortBy: SortOrder;
  sortOn: ListEventTypeSortOnField;
};

export type EventTypeWhereUniqueInput = {
  id: Scalars['ID']['input'];
};

export type EventTypesResponse = {
  __typename?: 'EventTypesResponse';
  count?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<Array<Maybe<EventType>>>;
};

export type GetAvailabilityFilter = {
  where: GetAvailabilityWhere;
};

export type GetAvailabilityWhere = {
  eventTypeSlug?: InputMaybe<Scalars['String']['input']>;
  isUnavailability?: InputMaybe<Scalars['Boolean']['input']>;
  userSlug: Scalars['String']['input'];
};

export type GetMonthlyAvailabilityFilter = {
  month?: InputMaybe<Scalars['Int']['input']>;
  where: GetMonthlyAvailabilityWhere;
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type GetMonthlyAvailabilityWhere = {
  eventTypeSlug?: InputMaybe<Scalars['String']['input']>;
  userSlug: Scalars['String']['input'];
};

export type GetProfileImageUploadSignedUrlInput = {
  fileName: Scalars['String']['input'];
};

export type GetSignedPutUrlResponse = {
  __typename?: 'GetSignedPutUrlResponse';
  fileName?: Maybe<Scalars['String']['output']>;
  signedUrl?: Maybe<Scalars['String']['output']>;
};

export type GetTokenInput = {
  refreshToken: Scalars['String']['input'];
};

export type GetTokenResponse = {
  __typename?: 'GetTokenResponse';
  accessToken?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
};

export type GetWorkspaceImageUploadSignedUrlInput = {
  fileName: Scalars['String']['input'];
};

export type InviteInput = {
  email: Scalars['String']['input'];
};

export type ListBookingSortOnField = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ListEventTypeSortOnField = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ListWorkspaceCredentialsInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type ListWorkspaceCredentialsResponse = {
  __typename?: 'ListWorkspaceCredentialsResponse';
  count?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<Array<Maybe<WorkspaceCredential>>>;
};

export type ListWorkspaceCredentialsSort = {
  sortBy: SortOrder;
  sortOn: ListWorkspaceCredentialsSortOnField;
};

export enum ListWorkspaceCredentialsSortOnField {
  AccessId = 'accessId',
  CreatedAt = 'createdAt'
}

export type ListWorkspaceInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type ListWorkspaceResponse = {
  __typename?: 'ListWorkspaceResponse';
  count?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<Array<Maybe<Workspace>>>;
};

export type ListWorkspaceSort = {
  sortBy: SortOrder;
  sortOn: ListWorkspaceSortOnField;
};

export enum ListWorkspaceSortOnField {
  CreatedAt = 'createdAt',
  Name = 'name'
}

export type ListWorkspaceWebhooksInput = {
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type ListWorkspaceWebhooksSort = {
  sortBy: SortOrder;
  sortOn: ListWorkspaceWebhooksSortOnField;
};

export enum ListWorkspaceWebhooksSortOnField {
  CreatedAt = 'createdAt',
  Description = 'description',
  Url = 'url'
}

export type LoginInput = {
  email: Scalars['String']['input'];
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  data?: Maybe<User>;
  message?: Maybe<Scalars['String']['output']>;
};

export type MonthlyAvailability = {
  __typename?: 'MonthlyAvailability';
  date?: Maybe<Scalars['Int']['output']>;
  day?: Maybe<Scalars['Int']['output']>;
  timeSlots?: Maybe<Scalars['JSON']['output']>;
};

export type MonthlyAvailabilityResponse = {
  __typename?: 'MonthlyAvailabilityResponse';
  count?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<Array<Maybe<MonthlyAvailability>>>;
  eventType?: Maybe<EventType>;
};

export type MonthlyBookingsFiler = {
  eventTypeSlug?: InputMaybe<Scalars['String']['input']>;
  month: Scalars['Int']['input'];
  statuses?: InputMaybe<Array<InputMaybe<BookingStatusEnum>>>;
  userSlug: Scalars['String']['input'];
  year: Scalars['Int']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  bulkCreateAvailability?: Maybe<CreateAvailabilityResponse>;
  bulkDeleteAvailability?: Maybe<CommonMessageResponse>;
  createAvailability?: Maybe<CreateAvailabilityResponse>;
  createBooking?: Maybe<CreateBookingResponse>;
  createCredentials?: Maybe<CreateCredentialsResponse>;
  createEventType?: Maybe<CreateEventTypeResponse>;
  createWebhook?: Maybe<CreateWebhookResponse>;
  createWorkspaceUser?: Maybe<CreateWorkspaceUserResponse>;
  deleteAvailability?: Maybe<CommonMessageResponse>;
  deleteEventType?: Maybe<CommonMessageResponse>;
  deleteWebhook?: Maybe<CommonMessageResponse>;
  getToken?: Maybe<GetTokenResponse>;
  inviteMemberInWorkspace?: Maybe<CommonMessageResponse>;
  login?: Maybe<LoginResponse>;
  logout?: Maybe<CommonMessageResponse>;
  resendInvitation?: Maybe<CommonMessageResponse>;
  updateAvailability?: Maybe<UpdateAvailabilityResponse>;
  updateBooking?: Maybe<UpdateBookingResponse>;
  updateEventType?: Maybe<UpdateEventTypeResponse>;
  updateUser?: Maybe<UpdateUserResponse>;
  updateWorkspace?: Maybe<UpdateWorkspaceResponse>;
  verifyOtp?: Maybe<VerifyOtpResponse>;
};


export type MutationBulkCreateAvailabilityArgs = {
  data: Array<CreateAvailabilityInput>;
};


export type MutationBulkDeleteAvailabilityArgs = {
  where: BulkDeleteAvailabilityWhere;
};


export type MutationCreateAvailabilityArgs = {
  data: CreateAvailabilityInput;
};


export type MutationCreateBookingArgs = {
  data: CreateBookingInput;
};


export type MutationCreateCredentialsArgs = {
  data: CreateCredentialsInput;
};


export type MutationCreateEventTypeArgs = {
  data: CreateEventTypeInput;
};


export type MutationCreateWebhookArgs = {
  data: CreateWebhookInput;
};


export type MutationCreateWorkspaceUserArgs = {
  data: CreateWorkspaceUserInput;
};


export type MutationDeleteAvailabilityArgs = {
  where: DeleteAvailabilityWhere;
};


export type MutationDeleteEventTypeArgs = {
  where: EventTypeWhereUniqueInput;
};


export type MutationDeleteWebhookArgs = {
  where: DeleteWebhookWhereUnique;
};


export type MutationGetTokenArgs = {
  data: GetTokenInput;
};


export type MutationInviteMemberInWorkspaceArgs = {
  data: InviteInput;
};


export type MutationLoginArgs = {
  data: LoginInput;
};


export type MutationResendInvitationArgs = {
  data: InviteInput;
};


export type MutationUpdateAvailabilityArgs = {
  data: UpdateAvailabilityInput;
  where: UpdateAvailabilityWhere;
};


export type MutationUpdateBookingArgs = {
  data: UpdateBookingInput;
  where: UpdateBookingWhereUnique;
};


export type MutationUpdateEventTypeArgs = {
  data: UpdateEventTypeInput;
  where: EventTypeWhereUniqueInput;
};


export type MutationUpdateUserArgs = {
  data: UpdateUserInput;
};


export type MutationUpdateWorkspaceArgs = {
  data: UpdateWorkspaceInput;
};


export type MutationVerifyOtpArgs = {
  data: VerifyOtpInput;
};

export type Query = {
  __typename?: 'Query';
  availability?: Maybe<AvailabilityResponse>;
  bookings?: Maybe<BookingsResponse>;
  currentUser?: Maybe<User>;
  eventType?: Maybe<EventType>;
  eventTypes?: Maybe<EventTypesResponse>;
  getProfileImageUploadSignedUrl?: Maybe<SignedUrlResponse>;
  getWorkspaceImageUploadSignedUrl?: Maybe<SignedUrlResponse>;
  monthlyAvailability?: Maybe<MonthlyAvailabilityResponse>;
  monthlyBookings?: Maybe<BookingsResponse>;
  user?: Maybe<User>;
  workspace?: Maybe<Workspace>;
  workspaceCredentials?: Maybe<ListWorkspaceCredentialsResponse>;
  workspaceWebhooks?: Maybe<WorkspaceWebhooksResponse>;
  workspaces?: Maybe<ListWorkspaceResponse>;
};


export type QueryAvailabilityArgs = {
  filter: GetAvailabilityFilter;
};


export type QueryBookingsArgs = {
  filter: BookingsFilter;
  sort?: InputMaybe<Array<BookingSort>>;
};


export type QueryEventTypeArgs = {
  where: EventTypeSlugWhereInput;
};


export type QueryEventTypesArgs = {
  filter: EventTypeFilter;
  sort?: InputMaybe<Array<EventTypeSort>>;
};


export type QueryGetProfileImageUploadSignedUrlArgs = {
  data: GetProfileImageUploadSignedUrlInput;
};


export type QueryGetWorkspaceImageUploadSignedUrlArgs = {
  data: GetWorkspaceImageUploadSignedUrlInput;
};


export type QueryMonthlyAvailabilityArgs = {
  filter: GetMonthlyAvailabilityFilter;
};


export type QueryMonthlyBookingsArgs = {
  filter: MonthlyBookingsFiler;
};


export type QueryUserArgs = {
  where: UserWhereUniqueInput;
};


export type QueryWorkspaceArgs = {
  filter?: InputMaybe<WorkspaceFilter>;
};


export type QueryWorkspaceCredentialsArgs = {
  filter: ListWorkspaceCredentialsInput;
  sort?: InputMaybe<Array<ListWorkspaceCredentialsSort>>;
};


export type QueryWorkspaceWebhooksArgs = {
  filter: ListWorkspaceWebhooksInput;
  sort?: InputMaybe<Array<ListWorkspaceWebhooksSort>>;
};


export type QueryWorkspacesArgs = {
  filter: ListWorkspaceInput;
  sort?: InputMaybe<Array<ListWorkspaceSort>>;
};

export type SignedUrlResponse = {
  __typename?: 'SignedUrlResponse';
  key?: Maybe<Scalars['String']['output']>;
  signedUrl?: Maybe<Scalars['String']['output']>;
};

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type TimeValues = {
  hour?: InputMaybe<Scalars['Int']['input']>;
  minute?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateAvailabilityInput = {
  availabilityDetails?: InputMaybe<Array<InputMaybe<CalendarDetails>>>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  isUnavailability?: InputMaybe<Scalars['Boolean']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UpdateAvailabilityResponse = {
  __typename?: 'UpdateAvailabilityResponse';
  data?: Maybe<Availability>;
  message?: Maybe<Scalars['String']['output']>;
};

export type UpdateAvailabilityWhere = {
  eventTypeId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateBookingInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<BookingStatusEnum>;
};

export type UpdateBookingResponse = {
  __typename?: 'UpdateBookingResponse';
  data?: Maybe<Booking>;
  message?: Maybe<Scalars['String']['output']>;
};

export type UpdateBookingWhereUnique = {
  id: Scalars['ID']['input'];
  status?: InputMaybe<BookingStatusEnum>;
};

export type UpdateEventTypeInput = {
  audienceSize?: InputMaybe<Scalars['Int']['input']>;
  buffer?: InputMaybe<Scalars['Int']['input']>;
  bufferUnit?: InputMaybe<Scalars['String']['input']>;
  bypassErrors?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateCount?: InputMaybe<Scalars['Int']['input']>;
  dateRangeEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  dateRangeStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  dateRangeType?: InputMaybe<DateRangeTypeEnum>;
  description?: InputMaybe<Scalars['String']['input']>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  durationUnit?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  recurringSessionDays?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  sessionCount?: InputMaybe<Scalars['Int']['input']>;
  sessionStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<EventTypeEnum>;
  upcomingSessionDates?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  useDefaultAvailability?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateEventTypeResponse = {
  __typename?: 'UpdateEventTypeResponse';
  data?: Maybe<EventType>;
  message?: Maybe<Scalars['String']['output']>;
};

export type UpdateUserInput = {
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  profileImage?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateUserResponse = {
  __typename?: 'UpdateUserResponse';
  data?: Maybe<User>;
  message?: Maybe<Scalars['String']['output']>;
};

export type UpdateWorkspaceInput = {
  config?: InputMaybe<Array<InputMaybe<WorkSpaceConfigInput>>>;
  description?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateWorkspaceResponse = {
  __typename?: 'UpdateWorkspaceResponse';
  data?: Maybe<Workspace>;
  message?: Maybe<Scalars['String']['output']>;
};

export type User = {
  __typename?: 'User';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  emailVerifiedAt?: Maybe<Scalars['DateTime']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  notificationPreferences?: Maybe<Scalars['JSON']['output']>;
  profileImage?: Maybe<Scalars['String']['output']>;
  refreshToken?: Maybe<Scalars['String']['output']>;
  roles?: Maybe<Array<Maybe<UserRolesEnum>>>;
  slug?: Maybe<Scalars['String']['output']>;
  timezone?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export enum UserRolesEnum {
  Admin = 'ADMIN',
  Guest = 'GUEST',
  SuperAdmin = 'SUPER_ADMIN',
  User = 'USER'
}

export type UserWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type VerifyOtpInput = {
  email: Scalars['String']['input'];
  otp: Scalars['String']['input'];
};

export type VerifyOtpResponse = {
  __typename?: 'VerifyOtpResponse';
  accessToken?: Maybe<Scalars['String']['output']>;
  data?: Maybe<User>;
  message?: Maybe<Scalars['String']['output']>;
};

export enum WebhookEventsEnum {
  BookingCancelled = 'booking_cancelled',
  BookingCreated = 'booking_created'
}

export type WorkSpaceConfigInput = {
  accessType?: InputMaybe<WorkspaceConfigTypes>;
  dataType?: InputMaybe<Scalars['String']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['JSON']['input']>;
};

export type Workspace = {
  __typename?: 'Workspace';
  config?: Maybe<Scalars['JSON']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  creator?: Maybe<User>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  users?: Maybe<Scalars['JSON']['output']>;
  uuid?: Maybe<Scalars['ID']['output']>;
};

export enum WorkspaceConfigTypes {
  Private = 'PRIVATE',
  Protected = 'PROTECTED',
  Public = 'PUBLIC'
}

export type WorkspaceCredential = {
  __typename?: 'WorkspaceCredential';
  accessId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  disabledAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  secretKey?: Maybe<Scalars['String']['output']>;
};

export type WorkspaceCredentials = {
  __typename?: 'WorkspaceCredentials';
  accessId?: Maybe<Scalars['String']['output']>;
  secretKey?: Maybe<Scalars['String']['output']>;
};

export type WorkspaceFilter = {
  configKeys?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type WorkspaceUser = {
  __typename?: 'WorkspaceUser';
  user?: Maybe<User>;
  workspace?: Maybe<Workspace>;
};

export type WorkspaceWebhook = {
  __typename?: 'WorkspaceWebhook';
  description?: Maybe<Scalars['String']['output']>;
  events?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  id?: Maybe<Scalars['ID']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  workspaceId?: Maybe<Scalars['ID']['output']>;
};

export type WorkspaceWebhooksResponse = {
  __typename?: 'WorkspaceWebhooksResponse';
  count?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<Array<Maybe<WorkspaceWebhook>>>;
};

export type LoginMutationVariables = Exact<{
  data: LoginInput;
}>;


export type LoginMutation = { __typename?: 'Mutation', login?: { __typename?: 'LoginResponse', message?: string | null, data?: { __typename?: 'User', email?: string | null, emailVerifiedAt?: any | null } | null } | null };

export type VerifyOtpMutationVariables = Exact<{
  data: VerifyOtpInput;
}>;


export type VerifyOtpMutation = { __typename?: 'Mutation', verifyOtp?: { __typename?: 'VerifyOtpResponse', accessToken?: string | null, message?: string | null, data?: { __typename?: 'User', email?: string | null, firstName?: string | null, lastName?: string | null, id?: string | null, profileImage?: string | null, roles?: Array<UserRolesEnum | null> | null } | null } | null };

export type UpdateUserMutationVariables = Exact<{
  data: UpdateUserInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser?: { __typename?: 'UpdateUserResponse', message?: string | null, data?: { __typename?: 'User', lastName?: string | null, firstName?: string | null, id?: string | null, email?: string | null, profileImage?: string | null } | null } | null };

export type CurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = { __typename?: 'Query', currentUser?: { __typename?: 'User', email?: string | null, id?: string | null, firstName?: string | null, lastName?: string | null, profileImage?: string | null, roles?: Array<UserRolesEnum | null> | null } | null };

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = { __typename?: 'Mutation', logout?: { __typename?: 'CommonMessageResponse', message?: string | null } | null };

export type WorkspacesQueryVariables = Exact<{
  filter: ListWorkspaceInput;
}>;


export type WorkspacesQuery = { __typename?: 'Query', workspaces?: { __typename?: 'ListWorkspaceResponse', count?: number | null, data?: Array<{ __typename?: 'Workspace', id?: string | null, image?: string | null, name?: string | null, uuid?: string | null } | null> | null } | null };

export type GetProfileImageUploadSignedUrlQueryVariables = Exact<{
  data: GetProfileImageUploadSignedUrlInput;
}>;


export type GetProfileImageUploadSignedUrlQuery = { __typename?: 'Query', getProfileImageUploadSignedUrl?: { __typename?: 'SignedUrlResponse', key?: string | null, signedUrl?: string | null } | null };

export type CreateBookingMutationVariables = Exact<{
  data: CreateBookingInput;
}>;


export type CreateBookingMutation = { __typename?: 'Mutation', createBooking?: { __typename?: 'CreateBookingResponse', message?: string | null, data?: { __typename?: 'Booking', id?: string | null, endTime?: any | null, startTime?: any | null, status?: BookingStatusEnum | null } | null } | null };

export type MonthlyAvailabilityQueryVariables = Exact<{
  filter: GetMonthlyAvailabilityFilter;
}>;


export type MonthlyAvailabilityQuery = { __typename?: 'Query', monthlyAvailability?: { __typename?: 'MonthlyAvailabilityResponse', count?: number | null, data?: Array<{ __typename?: 'MonthlyAvailability', date?: number | null, day?: number | null, timeSlots?: any | null } | null> | null, eventType?: { __typename?: 'EventType', id?: string | null, duration?: number | null, buffer?: number | null, type?: string | null, upcomingSessionDates?: Array<any | null> | null, sessionStartDate?: any | null, dateRangeType?: string | null } | null } | null };

export type BookingsQueryVariables = Exact<{
  filter: BookingsFilter;
}>;


export type BookingsQuery = { __typename?: 'Query', bookings?: { __typename?: 'BookingsResponse', data?: Array<{ __typename?: 'Booking', startTime?: any | null, endTime?: any | null, status?: BookingStatusEnum | null } | null> | null } | null };

export type MonthlyBookingsQueryVariables = Exact<{
  filter: MonthlyBookingsFiler;
}>;


export type MonthlyBookingsQuery = { __typename?: 'Query', monthlyBookings?: { __typename?: 'BookingsResponse', count?: number | null, data?: Array<{ __typename?: 'Booking', startTime?: any | null, endTime?: any | null, status?: BookingStatusEnum | null } | null> | null } | null };

export type AvailabilityQueryVariables = Exact<{
  filter: GetAvailabilityFilter;
}>;


export type AvailabilityQuery = { __typename?: 'Query', availability?: { __typename?: 'AvailabilityResponse', data?: Array<{ __typename?: 'Availability', startDate?: any | null, endDate?: any | null, isUnavailability?: boolean | null, availabilityDetails?: any | null } | null> | null } | null };

export type CreateWebhookMutationVariables = Exact<{
  data: CreateWebhookInput;
}>;


export type CreateWebhookMutation = { __typename?: 'Mutation', createWebhook?: { __typename?: 'CreateWebhookResponse', message?: string | null } | null };

export type DeleteWebhookMutationVariables = Exact<{
  where: DeleteWebhookWhereUnique;
}>;


export type DeleteWebhookMutation = { __typename?: 'Mutation', deleteWebhook?: { __typename?: 'CommonMessageResponse', message?: string | null } | null };

export type WorkspaceCredentialsQueryVariables = Exact<{
  filter: ListWorkspaceCredentialsInput;
}>;


export type WorkspaceCredentialsQuery = { __typename?: 'Query', workspaceCredentials?: { __typename?: 'ListWorkspaceCredentialsResponse', count?: number | null, data?: Array<{ __typename?: 'WorkspaceCredential', accessId?: string | null, id?: string | null, secretKey?: string | null } | null> | null } | null };

export type WorkspaceWebhooksQueryVariables = Exact<{
  filter: ListWorkspaceWebhooksInput;
  sort?: InputMaybe<Array<ListWorkspaceWebhooksSort> | ListWorkspaceWebhooksSort>;
}>;


export type WorkspaceWebhooksQuery = { __typename?: 'Query', workspaceWebhooks?: { __typename?: 'WorkspaceWebhooksResponse', data?: Array<{ __typename?: 'WorkspaceWebhook', url?: string | null, events?: Array<string | null> | null, description?: string | null, id?: string | null } | null> | null } | null };


export const LoginDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"Login"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"data"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"LoginInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"login"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"data"},"value":{"kind":"Variable","name":{"kind":"Name","value":"data"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"data"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"emailVerifiedAt"}}]}},{"kind":"Field","name":{"kind":"Name","value":"message"}}]}}]}}]} as unknown as DocumentNode<LoginMutation, LoginMutationVariables>;
export const VerifyOtpDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"VerifyOtp"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"data"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"VerifyOtpInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"verifyOtp"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"data"},"value":{"kind":"Variable","name":{"kind":"Name","value":"data"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"accessToken"}},{"kind":"Field","name":{"kind":"Name","value":"message"}},{"kind":"Field","name":{"kind":"Name","value":"data"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"firstName"}},{"kind":"Field","name":{"kind":"Name","value":"lastName"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"profileImage"}},{"kind":"Field","name":{"kind":"Name","value":"roles"}}]}}]}}]}}]} as unknown as DocumentNode<VerifyOtpMutation, VerifyOtpMutationVariables>;
export const UpdateUserDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateUser"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"data"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateUserInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateUser"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"data"},"value":{"kind":"Variable","name":{"kind":"Name","value":"data"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"message"}},{"kind":"Field","name":{"kind":"Name","value":"data"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"lastName"}},{"kind":"Field","name":{"kind":"Name","value":"firstName"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"profileImage"}}]}}]}}]}}]} as unknown as DocumentNode<UpdateUserMutation, UpdateUserMutationVariables>;
export const CurrentUserDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"CurrentUser"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"currentUser"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"firstName"}},{"kind":"Field","name":{"kind":"Name","value":"lastName"}},{"kind":"Field","name":{"kind":"Name","value":"profileImage"}},{"kind":"Field","name":{"kind":"Name","value":"roles"}}]}}]}}]} as unknown as DocumentNode<CurrentUserQuery, CurrentUserQueryVariables>;
export const LogoutDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"Logout"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"logout"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"message"}}]}}]}}]} as unknown as DocumentNode<LogoutMutation, LogoutMutationVariables>;
export const WorkspacesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"Workspaces"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"filter"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ListWorkspaceInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"workspaces"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"filter"},"value":{"kind":"Variable","name":{"kind":"Name","value":"filter"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"data"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"image"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"uuid"}}]}},{"kind":"Field","name":{"kind":"Name","value":"count"}}]}}]}}]} as unknown as DocumentNode<WorkspacesQuery, WorkspacesQueryVariables>;
export const GetProfileImageUploadSignedUrlDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetProfileImageUploadSignedUrl"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"data"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"GetProfileImageUploadSignedUrlInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getProfileImageUploadSignedUrl"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"data"},"value":{"kind":"Variable","name":{"kind":"Name","value":"data"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"key"}},{"kind":"Field","name":{"kind":"Name","value":"signedUrl"}}]}}]}}]} as unknown as DocumentNode<GetProfileImageUploadSignedUrlQuery, GetProfileImageUploadSignedUrlQueryVariables>;
export const CreateBookingDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateBooking"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"data"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreateBookingInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createBooking"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"data"},"value":{"kind":"Variable","name":{"kind":"Name","value":"data"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"message"}},{"kind":"Field","name":{"kind":"Name","value":"data"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"endTime"}},{"kind":"Field","name":{"kind":"Name","value":"startTime"}},{"kind":"Field","name":{"kind":"Name","value":"status"}}]}}]}}]}}]} as unknown as DocumentNode<CreateBookingMutation, CreateBookingMutationVariables>;
export const MonthlyAvailabilityDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"MonthlyAvailability"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"filter"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"GetMonthlyAvailabilityFilter"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"monthlyAvailability"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"filter"},"value":{"kind":"Variable","name":{"kind":"Name","value":"filter"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"data"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"date"}},{"kind":"Field","name":{"kind":"Name","value":"day"}},{"kind":"Field","name":{"kind":"Name","value":"timeSlots"}}]}},{"kind":"Field","name":{"kind":"Name","value":"count"}},{"kind":"Field","name":{"kind":"Name","value":"eventType"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"duration"}},{"kind":"Field","name":{"kind":"Name","value":"buffer"}},{"kind":"Field","name":{"kind":"Name","value":"type"}},{"kind":"Field","name":{"kind":"Name","value":"upcomingSessionDates"}},{"kind":"Field","name":{"kind":"Name","value":"sessionStartDate"}},{"kind":"Field","name":{"kind":"Name","value":"dateRangeType"}}]}}]}}]}}]} as unknown as DocumentNode<MonthlyAvailabilityQuery, MonthlyAvailabilityQueryVariables>;
export const BookingsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"Bookings"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"filter"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"BookingsFilter"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"bookings"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"filter"},"value":{"kind":"Variable","name":{"kind":"Name","value":"filter"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"data"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"startTime"}},{"kind":"Field","name":{"kind":"Name","value":"endTime"}},{"kind":"Field","name":{"kind":"Name","value":"status"}}]}}]}}]}}]} as unknown as DocumentNode<BookingsQuery, BookingsQueryVariables>;
export const MonthlyBookingsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"MonthlyBookings"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"filter"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"MonthlyBookingsFiler"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"monthlyBookings"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"filter"},"value":{"kind":"Variable","name":{"kind":"Name","value":"filter"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"count"}},{"kind":"Field","name":{"kind":"Name","value":"data"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"startTime"}},{"kind":"Field","name":{"kind":"Name","value":"endTime"}},{"kind":"Field","name":{"kind":"Name","value":"status"}}]}}]}}]}}]} as unknown as DocumentNode<MonthlyBookingsQuery, MonthlyBookingsQueryVariables>;
export const AvailabilityDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"Availability"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"filter"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"GetAvailabilityFilter"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"availability"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"filter"},"value":{"kind":"Variable","name":{"kind":"Name","value":"filter"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"data"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"startDate"}},{"kind":"Field","name":{"kind":"Name","value":"endDate"}},{"kind":"Field","name":{"kind":"Name","value":"isUnavailability"}},{"kind":"Field","name":{"kind":"Name","value":"availabilityDetails"}}]}}]}}]}}]} as unknown as DocumentNode<AvailabilityQuery, AvailabilityQueryVariables>;
export const CreateWebhookDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateWebhook"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"data"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreateWebhookInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createWebhook"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"data"},"value":{"kind":"Variable","name":{"kind":"Name","value":"data"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"message"}}]}}]}}]} as unknown as DocumentNode<CreateWebhookMutation, CreateWebhookMutationVariables>;
export const DeleteWebhookDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteWebhook"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"where"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"DeleteWebhookWhereUnique"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deleteWebhook"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"where"},"value":{"kind":"Variable","name":{"kind":"Name","value":"where"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"message"}}]}}]}}]} as unknown as DocumentNode<DeleteWebhookMutation, DeleteWebhookMutationVariables>;
export const WorkspaceCredentialsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"WorkspaceCredentials"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"filter"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ListWorkspaceCredentialsInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"workspaceCredentials"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"filter"},"value":{"kind":"Variable","name":{"kind":"Name","value":"filter"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"count"}},{"kind":"Field","name":{"kind":"Name","value":"data"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"accessId"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"secretKey"}}]}}]}}]}}]} as unknown as DocumentNode<WorkspaceCredentialsQuery, WorkspaceCredentialsQueryVariables>;
export const WorkspaceWebhooksDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"WorkspaceWebhooks"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"filter"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ListWorkspaceWebhooksInput"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"sort"}},"type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ListWorkspaceWebhooksSort"}}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"workspaceWebhooks"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"filter"},"value":{"kind":"Variable","name":{"kind":"Name","value":"filter"}}},{"kind":"Argument","name":{"kind":"Name","value":"sort"},"value":{"kind":"Variable","name":{"kind":"Name","value":"sort"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"data"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"url"}},{"kind":"Field","name":{"kind":"Name","value":"events"}},{"kind":"Field","name":{"kind":"Name","value":"description"}},{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]} as unknown as DocumentNode<WorkspaceWebhooksQuery, WorkspaceWebhooksQueryVariables>;