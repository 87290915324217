import { gql } from '../../../__generated__';

export const GET_CURRENT_USER = gql(`query CurrentUser {
  currentUser {
    email
    id
    firstName
    lastName
    profileImage
    roles
  }
}`);

export const LOGOUT_USER = gql(`mutation Logout {
  logout {
    message
  }
}`);

export const USER_WORKSPACE = gql(`
  query Workspaces($filter: ListWorkspaceInput!) {
    workspaces(filter: $filter) {
      data {
        id
        image
        name
        uuid
      }
      count
    }
  }
`);

export const GET_PROFILE_SIGNED_URL =
  gql(`query GetProfileImageUploadSignedUrl($data: GetProfileImageUploadSignedUrlInput!) {
  getProfileImageUploadSignedUrl(data: $data) {
    key
    signedUrl
  }
}`);
