import { Card, Spin } from 'antd';
import './auth.less';

const AuthWrapper = ({
  title,
  subTitle,
  isLoading,
  children,
}: {
  title: string;
  subTitle: string;
  isLoading: boolean;
  children: React.ReactNode;
}) => (
  <>
    <div className="auth-bg">
      <div className="login-wrap d-flex align-center justify-start">
        <Card className="full-width">
          <Spin spinning={isLoading} wrapperClassName="full-width">
            <div className="logo-wrapper">
              <h1>Calendar Scheduling</h1>
            </div>
            <div>
              <h2 className="login-title">{title}</h2>
              <h4 className="login-subtitle">{subTitle}</h4>
            </div>
            {children}
          </Spin>
        </Card>
        <div className="text-center login-copyrights">
          Calendar Scheduling © {new Date().getFullYear()}
        </div>
      </div>
    </div>
  </>
);

export default AuthWrapper;
