export const themeConfig = {
  token: {
    colorPrimary: 'var(--color-primary)',
    radiusBase: '4px',
    controlRadius: '4px',
    colorLink: 'var(--color-primary)',
    colorLinkActive: 'var(--color-primary)',
    colorLinkHover: 'var(--color-primary)',
  },
  components: {
    Button: {
      borderRadius: 4,
      colorPrimary: 'var(--color-primary)',
    },
  },
};
