import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Checkbox, Form } from 'antd';
import { useContext, useEffect } from 'react';
import OTPInput from 'react-otp-input';
import { NavLink } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { User, VerifyOtpInput } from '../../__generated__/graphql';
import {
  CURRENT_EMAIL,
  IS_NEW_USER,
  OTP_SIZE,
  REDIRECT_WAITING,
  REGEX,
  ROUTES,
} from '../../common/constants';
import { formValidatorRules, getItem, removeItem } from '../../common/utils';
import { messageContext } from '../../components/AppContextHolder';
import useRouter from '../../hooks/useRouter';
import { AppActionType, AppContextType } from '../../types/appContext.type';
import AuthWrapper from './AuthWrapper';
import { LOGIN, VERIFY_OTP } from './graphql/mutations';
import { USER_WORKSPACE } from './graphql/queries';

const { required, number } = formValidatorRules;

const VerifyUser = () => {
  const {
    state: { userEmail },
    initializeAuth,
    getToken,
    dispatch,
  } = useContext(AppContext) as AppContextType;
  const [form] = Form.useForm<VerifyOtpInput>();
  const { navigate } = useRouter();
  const idToken = getToken();

  const [verifyUserMutate, { loading }] = useMutation(VERIFY_OTP, {
    onError() {},
  });

  const [getWorkspace] = useLazyQuery(USER_WORKSPACE, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      dispatch({
        type: AppActionType.setWorkspace,
        data: res?.workspaces?.data?.[0]?.name ?? null,
      });
    },
  });

  const [resendOtp] = useMutation(LOGIN);

  useEffect(() => {
    const isEmail = getItem(CURRENT_EMAIL);
    if (idToken) {
      navigate(ROUTES.MAIN);
    } else if (!isEmail) {
      navigate(ROUTES.LOGIN);
    }
  }, []);

  const onFinish = async (values: VerifyOtpInput) => {
    const { otp } = values;
    if (otp?.length === OTP_SIZE) {
      verifyUserMutate({
        variables: {
          data: {
            email: userEmail,
            otp,
          },
        },
        onCompleted: async (response) => {
          if (response.verifyOtp) {
            initializeAuth(
              response?.verifyOtp?.accessToken ?? null,
              response.verifyOtp?.data as User,
            );
            getWorkspace({
              variables: {
                filter: {
                  limit: 10,
                  skip: 0,
                },
              },
            });
            removeItem(REDIRECT_WAITING);
            removeItem(IS_NEW_USER);
            navigate(ROUTES.MAIN);
          }
        },
      });
    } else {
      messageContext.error('Please enter correct OTP');
    }
  };

  const handleEnterOtp = (otp: string) => {
    if (REGEX.NUMBER.test(otp) || otp === '') {
      form.setFieldsValue({ otp });
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const validateTerms = (_: any, value: string) => {
    if (!value) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise?.reject('Please accept terms and conditions');
    }
    return Promise?.resolve();
  };

  const resendOtpHandler = () => {
    resendOtp({
      variables: {
        data: { email: userEmail },
      },
    });
  };

  return (
    <AuthWrapper
      title="Check Your Email"
      subTitle={`Enter OTP sent to ${userEmail}`}
      isLoading={false}
    >
      <Form form={form} onFinish={onFinish} className="otp-block">
        <Form.Item
          name="otp"
          initialValue=""
          rules={[{ ...required, message: 'Please enter OTP' }, number]}
          className="mb-16"
        >
          <OTPInput
            value={form.getFieldValue('otp')}
            onChange={handleEnterOtp}
            numInputs={6}
            renderInput={(props) => <input {...props} className="otp-input" />}
          />
        </Form.Item>
        <Form.Item
          name="agreement"
          valuePropName="checked"
          rules={[{ validator: validateTerms }]}
        >
          <Checkbox>
            I agree to the{' '}
            <NavLink to={ROUTES.MAIN} className="resend-text">
              terms and conditions{' '}
            </NavLink>
            and{' '}
            <NavLink to={ROUTES.MAIN} className="resend-text">
              privacy policy.
            </NavLink>
          </Checkbox>
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="full-width mt-24"
            loading={loading}
            disabled={loading}
          >
            Login
          </Button>
        </Form.Item>
        <p className="m-0">
          Didn't receive OTP ?{' '}
          <span onClick={resendOtpHandler} className="resend-text">
            Send Again
          </span>
        </p>
      </Form>
    </AuthWrapper>
  );
};

export default VerifyUser;
