import { gql } from '../../../__generated__';

export const CREATE_BOOKING =
  gql(`mutation CreateBooking($data: CreateBookingInput!) {
  createBooking(data: $data) {
    message
    data {
      id
      endTime
      startTime
      status
    }
  }
}`);
