import { UserOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Form, Input } from 'antd';
import { useContext } from 'react';
import { AppContext } from '../../AppContext';
import { LoginInput } from '../../__generated__/graphql';
import { CURRENT_EMAIL, IS_NEW_USER, ROUTES } from '../../common/constants';
import { formValidatorRules, setItem } from '../../common/utils';
import useRouter from '../../hooks/useRouter';
import { AppActionType, AppContextType } from '../../types/appContext.type';
import AuthWrapper from './AuthWrapper';
import './auth.less';
import { LOGIN } from './graphql/mutations';

const { required, email } = formValidatorRules;

const Login = () => {
  const [form] = Form.useForm<LoginInput>();
  const { navigate } = useRouter();
  const { dispatch } = useContext(AppContext) as AppContextType;

  const [loginMutate, { loading: loginLoading }] = useMutation(LOGIN, {
    onCompleted: (res) => {
      if (res?.login?.message === 'OTP sent successfully!') {
        const emailValue = form.getFieldValue('email');
        dispatch({ type: AppActionType.setEmail, data: emailValue });
        setItem(CURRENT_EMAIL, emailValue);
        if (res?.login?.data?.emailVerifiedAt) {
          setItem(
            IS_NEW_USER,
            res?.login?.data.emailVerifiedAt ? 'false' : 'true',
          );
        }
        navigate(ROUTES.VERIFY_USER);
      }
    },
    onError() {}, // Always write this method for error handling in all mutation.
  });

  const onFinish = async (values: LoginInput) => {
    const { email: userEmail } = values;
    // converted email into lowercase
    const lowerCaseEmail = userEmail?.toLowerCase();
    loginMutate({
      variables: {
        data: {
          email: lowerCaseEmail,
        },
      },
    });
  };

  return (
    <AuthWrapper
      title="Get Started"
      subTitle="You will receive 6 digit OTP in your inbox for verification"
      isLoading={false}
    >
      <Form
        name="Login"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        size="large"
        form={form}
      >
        <Form.Item
          name="email"
          rules={[{ ...required, message: 'Please enter email!' }, email]}
        >
          <Input prefix={<UserOutlined />} placeholder="Enter email" />
        </Form.Item>

        <Form.Item className=" full-width mb-8">
          <Button
            type="primary"
            className="full-width"
            htmlType="submit"
            loading={loginLoading}
            disabled={loginLoading}
          >
            Login
          </Button>
        </Form.Item>
      </Form>
    </AuthWrapper>
  );
};

export default Login;
