import { DeleteOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Form, Modal, Table, TableProps, Typography } from 'antd';
import { useEffect, useState } from 'react';
import {
  CreateWebhookInput,
  ListWorkspaceWebhooksSortOnField,
  SortOrder,
} from '../../../__generated__/graphql';
import useRouter from '../../../hooks/useRouter';
import CreateWebhookForm from '../components/CreateWebhookForm';
import { CREATE_WEBHOOK, DELETE_WEBHOOK } from '../graphql/mutations';
import { GET_WORKSPACE_WEBHOOKS } from '../graphql/queries';

const { Title } = Typography;

type WebhookDataType = {
  id: string;
  key: string;
  url: string;
  events: string[];
  description: string;
};

const Webhook = () => {
  const { params } = useRouter();
  const [form] = Form.useForm<CreateWebhookInput>();

  const [webhooks, setWebhooks] = useState<WebhookDataType[]>();
  const [createModal, setCreateModal] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<string | null>(null);

  const [getWebhooks, { loading }] = useLazyQuery(GET_WORKSPACE_WEBHOOKS, {
    fetchPolicy: 'network-only',
    context: { headers: { 'x-workspace-uuid': params?.id } },
    variables: {
      filter: { limit: 10, skip: 0 },
      sort: {
        sortBy: SortOrder.Desc,
        sortOn: ListWorkspaceWebhooksSortOnField.CreatedAt,
      },
    },
    onCompleted: (res) => {
      if (res.workspaceWebhooks?.data) {
        setWebhooks(res.workspaceWebhooks?.data as WebhookDataType[]);
      }
    },
  });

  const handleCloseModal = () => {
    setCreateModal(false);
  };

  const [createWebhook, { loading: createLoader }] = useMutation(
    CREATE_WEBHOOK,
    {
      context: { headers: { 'x-workspace-uuid': params?.id } },
      onError() {},
      onCompleted: () => {
        getWebhooks();
        handleCloseModal();
      },
    },
  );

  const [deleteWebhook, { loading: deleteLoading }] = useMutation(
    DELETE_WEBHOOK,
    { onError() {} },
  );

  const onFinish = (values: CreateWebhookInput) => {
    createWebhook({
      variables: { data: values },
    });
  };

  const handleDelete = (id: string) => {
    deleteWebhook({
      context: { headers: { 'x-workspace-uuid': params?.id } },
      variables: { where: { id } },
      onCompleted: () => {
        setDeleteId(null);
        getWebhooks();
      },
    });
  };

  const columns: TableProps<WebhookDataType>['columns'] = [
    {
      title: 'URL',
      dataIndex: 'url',
      key: 'url',
      width: '30%',
    },
    {
      title: 'Events',
      dataIndex: 'events',
      key: 'events',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (value) => value ?? '-',
    },
    {
      title: 'Action',
      key: 'action',
      width: '150px',
      render: (_, record) => (
        <DeleteOutlined onClick={() => setDeleteId(record.id)} />
      ),
    },
  ];

  useEffect(() => {
    getWebhooks();
  }, []);
  return (
    <>
      <div className="d-flex align-center justify-between mb-8">
        <Title className="site-page-header p-0 mb-0 mt-0" level={3}>
          Webhooks
        </Title>
        <Button type="primary" onClick={() => setCreateModal(true)}>
          Add Webhook
        </Button>
      </div>
      <Table
        columns={columns}
        dataSource={webhooks}
        pagination={false}
        loading={loading}
      />
      <CreateWebhookForm
        open={createModal}
        onCancel={handleCloseModal}
        form={form}
        loading={createLoader}
        onFinish={onFinish}
      />
      <Modal
        title="Delete the webhook"
        okText="Yes"
        cancelText="No"
        open={!!deleteId}
        onOk={() => handleDelete(deleteId ?? '')}
        okButtonProps={{ loading: deleteLoading, disabled: deleteLoading }}
        onCancel={() => setDeleteId(null)}
      >
        Are you sure you want to delete this webhook?
      </Modal>
    </>
  );
};
export default Webhook;
