import { Route, Routes } from 'react-router-dom';
import Error404 from '../../Error404';
import { ROUTES } from '../../common/constants';
import WorkspaceTab from './components/WorkspaceTabs';

const WorkspaceWrapper = () => (
  <Routes>
    <Route path={`${ROUTES.AUTH_DETAILS}/:id`} element={<WorkspaceTab />} />
    <Route path={`${ROUTES.WEBHOOKS}/:id`} element={<WorkspaceTab />} />
    <Route path="*" element={<Error404 />} />
  </Routes>
);

export default WorkspaceWrapper;
