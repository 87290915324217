import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Button, Card, Col, Form, Input, Row, Space, Upload } from 'antd';
import { UploadChangeParam, UploadFile } from 'antd/es/upload';
import axios from 'axios';
import { useContext, useState } from 'react';
import { AppContext } from '../../AppContext';
import { UpdateUserInput } from '../../__generated__/graphql';
import editIcon from '../../assets/images/edit-icon.png';
import { ALL_IMAGE_UPLOAD } from '../../common/constants';
import {
  formValidatorRules,
  handleProtectedNavigation,
  previewImage,
} from '../../common/utils';
import { messageContext } from '../../components/AppContextHolder';
import RouterPrompt from '../../components/RouterPrompt';
import useRouter from '../../hooks/useRouter';
import { AppActionType, AppContextType } from '../../types/appContext.type';
import { UPDATE_CURRENT_USER } from '../auth/graphql/mutations';
import {
  GET_CURRENT_USER,
  GET_PROFILE_SIGNED_URL,
} from '../auth/graphql/queries';

const { required, name, email } = formValidatorRules;

function Profile() {
  const { dispatch, initializeAuth, getToken } = useContext(
    AppContext,
  ) as AppContextType;

  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  const [showPrompt, setShowPrompt] = useState<boolean>(false);
  const [isPrompt, setIsPrompt] = useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [currentFile, setCurrentFile] = useState<UploadFile<any> | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [imageUpload, setImageUpload] = useState<UploadFile<any>[]>([]);
  const [state, setState] = useState<{
    selectedImage: Blob | null;
    uploading: boolean;
  }>({
    selectedImage: null,
    uploading: false,
  });

  const [form] = Form.useForm<UpdateUserInput>();
  const { navigate } = useRouter();
  const idToken = getToken();
  const { data: userData } = useQuery(GET_CURRENT_USER);

  const [updateCurrentUser] = useMutation(UPDATE_CURRENT_USER, {
    onCompleted: (res) => {
      if (res?.updateUser?.data) {
        setBtnLoading(false);
        dispatch({
          type: AppActionType.setCurrentUser,
          data: res?.updateUser?.data,
        });
        initializeAuth(idToken, res?.updateUser?.data);
      }
    },
    onError: () => {
      form.setFieldsValue(userData as UpdateUserInput);
      setBtnLoading(false);
    },
  });

  const [getSignedURL] = useLazyQuery(GET_PROFILE_SIGNED_URL, {
    onError() {
      setBtnLoading(false);
      setState({ ...state, uploading: false, selectedImage: null });
    },
  });

  // comment as of now will use in future
  // useEffect(() => {
  //   if (userData?.currentUser?.profileImage) {
  //     setImageUpload([
  //       { url: userData?.currentUser?.profileImage } as UploadFile,
  //     ]);
  //   } else {
  //     setImageUpload([]);
  //   }
  // }, [userData]);

  const onChangeImageUpload = async (info: UploadChangeParam) => {
    const {
      file: { name = '', url },
    } = info;

    const ext = name?.substring(name?.lastIndexOf('.') + 1);

    if (ALL_IMAGE_UPLOAD?.includes(ext) && !url) {
      setImageUpload([...info?.fileList]);
      setCurrentFile(info?.file);
    } else if (info?.file?.status === 'removed') {
      setCurrentFile(null);
      setImageUpload([]);
    } else {
      messageContext.destroy();
      messageContext.error(`${info?.file?.name} file is not image file.`);
    }
  };

  const handleBack = () => {
    setIsPrompt(!handleProtectedNavigation(!showPrompt, navigate, -1));
  };

  const handleShowPrompt = () => {
    setShowPrompt(true);
  };

  const handleOk = () => {
    handleProtectedNavigation(true, navigate, -1);
  };

  const handleClose = () => {
    setIsPrompt(false);
  };

  const onFinish = async (values: UpdateUserInput) => {
    setBtnLoading(true);
    setShowPrompt(false);
    const userObj = {
      firstName: values?.firstName?.trim(),
      lastName: values?.lastName?.trim(),
    };
    setState({
      ...state,
      uploading: true,
    });

    if (currentFile) {
      getSignedURL({
        variables: {
          data: {
            fileName: currentFile?.name,
          },
        },
        onCompleted: async (res) => {
          if (res.getProfileImageUploadSignedUrl?.signedUrl) {
            try {
              await axios?.put(
                res?.getProfileImageUploadSignedUrl?.signedUrl,
                currentFile,
              );
              const data = {
                ...userObj,
                profileImage: res?.getProfileImageUploadSignedUrl?.key,
              };
              await updateCurrentUser({
                variables: {
                  data,
                },
              });
            } catch (err) {
              return err;
            }
          }
        },
      });
    } else {
      updateCurrentUser({
        variables: {
          data: {
            ...userObj,
            profileImage: userData?.currentUser?.profileImage || null,
          },
        },
      });
    }
  };
  return (
    <>
      {userData?.currentUser && (
        <Form
          form={form}
          className="sticky-action-form"
          onFieldsChange={handleShowPrompt}
          layout="vertical"
          initialValues={userData?.currentUser}
          onFinish={onFinish}
        >
          <Card
            className="ant-body-scroll"
            title="Profile"
            actions={[
              <div key="actionbutton" className="text-right">
                <Space>
                  <Button onClick={handleBack} disabled={btnLoading}>
                    Cancel
                  </Button>
                  <Button type="primary" loading={btnLoading} htmlType="submit">
                    Save
                  </Button>
                </Space>
              </div>,
            ]}
          >
            <div className="card-body-wrapper">
              <Row gutter={[16, 16]}>
                <Col xs={24} lg={24} xl={24}>
                  <Form.Item name="profileImage" label="Profile photo">
                    <Upload
                      maxCount={1}
                      onChange={onChangeImageUpload}
                      previewFile={previewImage}
                      fileList={imageUpload}
                      beforeUpload={() => false}
                      listType="picture-card"
                      showUploadList={{ showPreviewIcon: false }}
                    >
                      {!imageUpload?.length && (
                        <img src={editIcon} alt="icon" className="icon-image" />
                      )}
                    </Upload>
                  </Form.Item>
                </Col>
                <Col xs={24} lg={8} xl={8}>
                  <Form.Item
                    name="firstName"
                    label="First Name"
                    rules={[
                      { ...required, message: 'Please Enter First Name' },
                      name,
                    ]}
                  >
                    <Input placeholder="Enter First Name" />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={8} xl={8}>
                  <Form.Item
                    name="lastName"
                    label="Last Name"
                    rules={[
                      { ...required, message: 'Please Enter Last Name' },
                      name,
                    ]}
                  >
                    <Input placeholder="Enter Last Name" />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={8} xl={8}>
                  <Form.Item
                    name="email"
                    label="Email"
                    rules={[
                      { ...required, message: 'Please Enter Email' },
                      email,
                    ]}
                  >
                    <Input disabled placeholder="Enter Email" />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Card>
        </Form>
      )}
      <RouterPrompt
        isPrompt={isPrompt}
        handleOK={handleOk}
        handleCancel={handleClose}
      />
    </>
  );
}
export default Profile;
