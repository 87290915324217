import { gql } from '../../../__generated__';

export const CREATE_WEBHOOK =
  gql(`mutation CreateWebhook($data: CreateWebhookInput!) {
  createWebhook(data: $data) {
    message
  }
}`);

export const DELETE_WEBHOOK =
  gql(`mutation DeleteWebhook($where: DeleteWebhookWhereUnique!) {
  deleteWebhook(where: $where) {
    message
  }
}
`);
