import { Form, FormInstance, Input, Modal, Select } from 'antd';
import { CreateWebhookInput } from '../../../__generated__/graphql';
import { formValidatorRules } from '../../../common/utils';

const { TextArea } = Input;

const { required } = formValidatorRules;

const eventsOptions = [
  { label: 'Booking created', value: 'booking_created' },
  { label: 'Booking cancelled', value: 'booking_cancelled' },
];

const CreateWebhookForm = ({
  open,
  onCancel,
  form,
  loading,
  onFinish,
}: {
  open: boolean;
  onCancel: () => void;
  form: FormInstance<CreateWebhookInput>;
  loading: boolean;
  // eslint-disable-next-line no-unused-vars
  onFinish: (values: CreateWebhookInput) => void;
}) => {
  return (
    <>
      <Modal
        open={open}
        title={<h2>Create Webhook</h2>}
        closeIcon={false}
        onOk={form.submit}
        okText="Create"
        onCancel={onCancel}
        okButtonProps={{ loading: loading, disabled: loading }}
      >
        <Form layout="vertical" form={form} onFinish={onFinish}>
          <Form.Item
            name="url"
            label="URL :"
            rules={[{ ...required, message: 'Please enter url!' }]}
          >
            <Input placeholder="Enter URL" />
          </Form.Item>
          <Form.Item
            name="events"
            label="Events :"
            rules={[{ ...required, message: 'Please select event!' }]}
          >
            <Select
              placeholder="Select events"
              mode="multiple"
              options={eventsOptions}
            />
          </Form.Item>
          <Form.Item name="description" label="Description :">
            <TextArea rows={5} placeholder="Enter description" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
export default CreateWebhookForm;
