import { CopyOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { Table, TableProps, Tooltip, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { messageContext } from '../../../components/AppContextHolder';
import useRouter from '../../../hooks/useRouter';
import { GET_WORKSPACE_AUTH_DETAILS } from '../graphql/queries';

const { Title } = Typography;

type AuthDetailDataType = {
  key: string;
  keys: string;
  value: string;
};

const AuthDetails = () => {
  const { params } = useRouter();
  const [authDetails, setAuthDetails] = useState<AuthDetailDataType[]>();

  const [getAuthDetails, { loading }] = useLazyQuery(
    GET_WORKSPACE_AUTH_DETAILS,
    {
      variables: { filter: { limit: 10, skip: 0 } },
      context: { headers: { 'x-workspace-uuid': params?.id } },
      fetchPolicy: 'network-only',
    },
  );

  useEffect(() => {
    getAuthDetails({
      onCompleted: (res) => {
        const item =
          res.workspaceCredentials?.data &&
          res.workspaceCredentials?.data?.length > 0
            ? [
                {
                  key: '1',
                  keys: 'Api Key',
                  value: res.workspaceCredentials?.data?.[0]?.accessId || '',
                },
                {
                  key: '2',
                  keys: 'Secret Key',
                  value: res.workspaceCredentials?.data?.[0]?.secretKey || '',
                },
              ]
            : [];
        setAuthDetails(item);
      },
    });
  }, []);

  const handleCopy = async (key: string, value: string) => {
    await navigator.clipboard.writeText(value);
    messageContext.success(`${key} copied successfully`);
  };

  const columns: TableProps<AuthDetailDataType>['columns'] = [
    {
      title: 'Keys',
      dataIndex: 'keys',
      key: 'keys',
      width: '30%',
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
    },
    {
      title: 'Action',
      key: 'action',
      width: '150px',
      render: (_, record) => (
        <Tooltip title="Copy text">
          <CopyOutlined onClick={() => handleCopy(record.keys, record.value)} />
        </Tooltip>
      ),
    },
  ];

  return (
    <>
      <Title className="site-page-header p-0 mb-0 mt-0" level={3}>
        Auth Details
      </Title>
      <p className="mb-8 mt-0">
        API keys allow other apps to communicate with Calendar Scheduling
      </p>
      <Table
        columns={columns}
        dataSource={authDetails}
        pagination={false}
        loading={loading}
      />
    </>
  );
};
export default AuthDetails;
