import { gql } from '../../../__generated__';

export const GET_WORKSPACE_AUTH_DETAILS =
  gql(`query WorkspaceCredentials($filter: ListWorkspaceCredentialsInput!) {
  workspaceCredentials(filter: $filter) {
    count
    data {
      accessId
      id
      secretKey
    }
  }
}`);

export const GET_WORKSPACE_WEBHOOKS =
  gql(`query WorkspaceWebhooks($filter: ListWorkspaceWebhooksInput!, $sort: [ListWorkspaceWebhooksSort!]) {
    workspaceWebhooks(filter: $filter, sort: $sort) {
      data {
        url
        events
        description
        id
      }
    }
  }`);
