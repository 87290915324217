import { HomeOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { MODULES, ROUTES } from '../../../common/constants';
import useRouter from '../../../hooks/useRouter';

function getItem(
  label: string,
  key: string,
  icon: React.ReactNode,
  children?: React.ReactNode,
  type?: string,
) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const menuItems = [getItem(MODULES.DASHBOARD, ROUTES.MAIN, <HomeOutlined />)];

function Sidebar() {
  const {
    navigate,
    location: { pathname },
  } = useRouter();
  const onMenuSelect = (e: { key: React.Key }) => {
    navigate(e.key.toString());
  };

  return (
    <div className="side-bar">
      <Menu
        theme="light"
        mode="inline"
        selectedKeys={[`/${pathname?.split('/')?.[1]}`]}
        defaultSelectedKeys={[ROUTES.MAIN]}
        onSelect={onMenuSelect}
        onClick={onMenuSelect}
        items={menuItems}
      />
    </div>
  );
}

export default Sidebar;
