import { CloseCircleOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { Result, Typography } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, useRoutes } from 'react-router-dom';
import { AppContext } from './AppContext';
import Error404 from './Error404';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import App from './app/App';
import {
  ERROR_PAGE_SUBTITLE,
  ERROR_PAGE_TITLE,
  ROUTES,
} from './common/constants';
import LoaderComponent from './components/LoaderComponent';
import MaintenancePage from './components/MaintenancePage';
import history from './historyData';
// import ForgetPassword from './modules/auth/ForgetPassword';
import Login from './modules/auth/Login';
// import Logout from './modules/auth/Logout';
// import RefreshToken from './modules/auth/RefreshToken';
// import ResetPassword from './modules/auth/ResetPassword';
// import Signup from './modules/auth/Signup';
import Logout from './modules/auth/Logout';
import VerifyUser from './modules/auth/VerifyUser';
import {
  GET_CURRENT_USER,
  USER_WORKSPACE,
} from './modules/auth/graphql/queries';
import BookingWrapper from './modules/booking';
import DashboardWrapper from './modules/dashboard';
import Profile from './modules/profile/Profile';
import WorkspaceWrapper from './modules/workspace';
import { AppActionType, AppContextType } from './types/appContext.type';

type FallbackProps = {
  error: Error | null;
  componentStack: string | null;
};

const { Paragraph } = Typography;

const MyFallbackComponent = ({ error, componentStack }: FallbackProps) => (
  <Result
    status="error"
    title={ERROR_PAGE_TITLE}
    subTitle={ERROR_PAGE_SUBTITLE}
  >
    <div className="desc">
      <Paragraph>
        <Typography.Title level={4}> Error:</Typography.Title>
      </Paragraph>
      <Paragraph>
        <CloseCircleOutlined className="site-result-demo-error-icon" /> Your
        {error?.message?.toString()}
      </Paragraph>
      <Paragraph>
        <Typography.Title level={4}> Stacktrace:</Typography.Title>
      </Paragraph>
      <Paragraph>
        <CloseCircleOutlined className="site-result-demo-error-icon" /> Your
        {componentStack}
      </Paragraph>
    </div>
  </Result>
);

const RoutesCollection = () => {
  const AUTH_MODULES = [
    {
      path: ROUTES.LOGIN,
      element: <PublicRoute />,
      // Nested routes use a children property
      children: [{ path: ROUTES.LOGIN, element: <Login /> }],
    },
    {
      path: ROUTES.VERIFY_USER,
      element: <PublicRoute />,
      children: [{ path: ROUTES.VERIFY_USER, element: <VerifyUser /> }],
    },
    {
      path: ROUTES.LOGOUT,
      element: <PrivateRoute />,
      children: [{ path: ROUTES.LOGOUT, element: <Logout /> }],
    },
  ];

  const DASHBOARD_MODULES = [
    {
      path: ROUTES.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES.MAIN,
          element: <App />,
          children: [{ path: ROUTES.MAIN, element: <DashboardWrapper /> }],
        },
      ],
    },
  ];

  const USER_MODULES = [
    {
      path: ROUTES.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES.MAIN,
          element: <App />,
          children: [
            {
              path: ROUTES.PROFILE,
              element: <Profile />,
            },
          ],
        },
      ],
    },
  ];

  const WORKSPACE_MODULES = [
    {
      path: ROUTES.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES.MAIN,
          element: <App />,
          children: [
            { path: `${ROUTES.WORKSPACE}/*`, element: <WorkspaceWrapper /> },
          ],
        },
      ],
    },
  ];

  const BOOKING_MODULES = [
    {
      path: `${ROUTES.BOOKING}/*`,
      element: <BookingWrapper />,
    },
  ];

  const OTHER_MODULES = [
    {
      path: ROUTES.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES.MAIN,
          element: <App />,
          children: [
            {
              path: '*',
              element: <Error404 />,
            },
          ],
        },
      ],
    },
  ];

  const element = useRoutes([
    ...AUTH_MODULES,
    ...DASHBOARD_MODULES,
    ...USER_MODULES,
    ...OTHER_MODULES,
    ...WORKSPACE_MODULES,
    ...BOOKING_MODULES,
  ]);
  return element;
};

const RoutesWrapper = () => {
  const { initializeAuth, getToken, dispatch } = useContext(
    AppContext,
  ) as AppContextType;
  const [loading, setLoading] = useState(true);
  const path = history?.location?.pathname;
  const idToken = getToken();

  const [getWorkspace] = useLazyQuery(USER_WORKSPACE, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      dispatch({
        type: AppActionType.setWorkspace,
        data: res?.workspaces?.data?.[0]?.name ?? null,
      });
    },
  });

  const [getCurrentUser] = useLazyQuery(GET_CURRENT_USER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      initializeAuth(idToken, res?.currentUser ?? null);
      getWorkspace({
        variables: {
          filter: {
            limit: 10,
            skip: 0,
          },
        },
      });
      setLoading(false);
    },
    onError: () => {
      history?.push(ROUTES.LOGOUT);
      setLoading(false);
    },
  });

  useEffect(() => {
    if (path === ROUTES.LOGOUT || idToken) {
      getCurrentUser();
    } else {
      setLoading(false);
    }

    // Below line is disabling Eslint auto fix we don't want any value in use effect array
    // We want to call initializeAuth once. Please add this line while you working with hooks and you want to call it once.
    // eslint-disable-next-line
  }, []);

  // use this variable from envs so that we can able to run maintenance page on runtime.
  const maintenance = process.env.REACT_APP_MAINTENANCE_ENABLE;

  if (loading) return <LoaderComponent />;
  return (
    <Sentry.ErrorBoundary fallback={MyFallbackComponent}>
      <Router>
        {maintenance === 'true' ? <MaintenancePage /> : <RoutesCollection />}
      </Router>
    </Sentry.ErrorBoundary>
  );
};
export default RoutesWrapper;
