import { Route, Routes } from 'react-router-dom';
import Error404 from '../../Error404';
import { ROUTES } from '../../common/constants';
import Booking from './Booking';
import './booking.less';

const BookingWrapper = () => (
  <Routes>
    <Route path={ROUTES.MAIN} element={<Booking />} />
    <Route path="*" element={<Error404 />} />
  </Routes>
);

export default BookingWrapper;
